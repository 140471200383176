<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="gold-log-layout">
      <div class="gl-page-header">
        <el-page-header :title="$t('common.i_back')" :content="$t('index.i_coinsLog')" @back="$router.push('/index')" style="float: left;" />
        <div style="float: right;background-color: rgba(222, 231, 233, 1);padding: 2px 6px 3px 6px;border-radius: 20px;">
          <img src="@/assets/common/goldIcon.png" style="width: 14px;vertical-align: middle;" />
          <span style="padding-right: 2px;font-size: 14px;color: black;text-decoration: none;vertical-align: middle;"> {{$store.state.goldNum}}</span>
        </div>
      </div>

      <div style="margin-top: 5%;margin-left: 5%;margin-right: 5%;overflow-y: scroll;height: 88vh;">
        <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
          <div v-for="log in goldNumLog" :key="log.id" style="background-color: rgba(222, 231, 233, 1);margin-bottom: 3%;padding: 4%;border-radius: 8px;">
            <div style="overflow: hidden;color: rgba(0, 0, 0, 1);">
              <div style="float: left;margin-top: 3%;font-size: 22px;">
                <div v-if="log.goldNum > 0" style="float: left;margin-top: 5%;">+{{log.goldNum}}</div>
                <div v-else style="float: left;margin-top: 5%;">{{log.goldNum}}</div>
                <img src="@/assets/common/goldIcon.png" style="margin-left: 4px;width: 30px;" />
              </div>
              <div style="float: right;">
                {{log.desc}}
                <div style="margin-top: 10%;text-align: right;">
                  {{log.logDate}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="goldNumLog==''">
          <img src="@/assets/common/noIcon.png" style="margin-top: 30%;width: 150px;" />
          <div style="margin-top: 5%;color: rgba(161, 180, 189, 1);">{{$t('inviteFriend.i_noLog')}}</div>
        </div>
      </div>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{ goldLog.failMessage }}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isLoading: false,
        checkDialog: false,
        goldNumLog: [],
        busy: false,
        pageNum: 0,
        isLast: false,
        goldLog: {
          check: "",
          failMessage: "",
          appScheme: ""
        }
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      go() {
        if (this.goldLog.check == "login") {
          window.location.href = this.goldLog.appScheme + "login";
        } else {
          window.location.href = this.goldLog.appScheme;
        }
      },
      loadMore: function () {
        if (this.isLast != true) {
          this.busy = true;
          this.pageNum++;
          this.isLoading = true;
          this.$axios({
            url: "/webGift/goldLog",
            method: "get",
            params: {
              pageNum: this.pageNum
            }
          }).then((result) => {
            this.isLoading = false;
            if (result.data.code == 1) {
              result.data.data.log.forEach((element) => {
                this.goldNumLog.push(element);
              });
              this.isLast = result.data.data.isLast;
            }
          });
          this.busy = false;
        }
      }
    },
    created() {
      this.isLoading = true;
      this.$axios({
        url: "/webGift/goldLog",
        method: "get",
        params: {
          pageNum: 1
        }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.$store.state.goldNum = result.data.data.goldNum;
        } else if (result.data.code == -2) {
          this.goldLog.check = result.data.data.check;
          this.goldLog.failMessage = result.data.message;
          this.goldLog.appScheme = result.data.data.appScheme;
          this.checkDialog = true;
        }
      });
    }
  };
</script>

<style>
  #gold-log-layout {
    width: 100%;
    height: 100%;
  }
  #gold-log-layout .gl-page-header {
    overflow: hidden;
    padding: 4% 4% 0 4%;
  }
</style>
