<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <!-- 首页版心 -->
    <div id="index-layout">
      <div class="i-bg-img">
        <div class="i-page-header">
          <el-page-header :title="$t('common.i_back')" @back="goApp()" style="float: left;" />
        </div>

        <!-- 标题 -->
        <div style="padding-top: 1%;font-size: 18px;font-weight: bold;color: rgba(226, 255, 231, 1);height: 22px;">
          {{data.appName}}
        </div>

        <!-- 现金图片 -->
        <div style="margin-top: 4%;">
          <img src="@/assets/images/index/indexCashImg.png" style="width: 160px;" />
        </div>

        <!-- 邀请活动 -->
        <div @click="data.userInfo.failMessage!=null?checkDialog=true:$router.push({name: 'inviteFriend', params: {inviteEventDrawer: true}})" style="position: absolute;top: 22%;right: 2%;">
          <img src="@/assets/images/index/inviteEvent.gif" style="width: 80px;" />
        </div>

        <!-- 总金币标题 -->
        <div style="margin-top: 2%;">{{$t('index.i_totalCoins')}}</div>

        <!-- 总金币 -->
        <div style="margin-top: 4%;padding-bottom: 4%;">
          <img src="@/assets/common/goldIcon.png" style="width: 34px;vertical-align: middle;" />
          <span style="margin-left: 4px;font-size: 42px;font-weight: bold;color: black;vertical-align: middle;">{{$store.state.goldNum}}</span>
        </div>
      </div>

      <!-- 菜单项 -->
      <div style="display: inline-flex;margin-top: 4%;">
        <!-- 账号 -->
        <div @click="userInfoDialog = true">
          <img src="@/assets/images/index/user.png" style="width: 50px;" />
          <div>{{$t('index.i_account')}}</div>
        </div>
        <!-- 排行榜 -->
        <div @click="data.userInfo.failMessage!=null?checkDialog=true:$router.push('/leaderboard')" style="margin: auto 10px auto 20px;">
          <img src="@/assets/images/index/leaderboard.png" style="width: 50px;" />
          <div>{{$t('index.i_leaderboard')}}</div>
        </div>
        <!-- 联系我们 -->
        <div @click="contactDrawer = true" style="margin: auto 20px auto 10px;">
          <img src="@/assets/images/index/contact.png" style="width: 50px;" />
          <div>{{$t('index.i_contact')}}</div>
        </div>
        <!-- 金币记录 -->
        <div @click="data.userInfo.failMessage!=null?checkDialog=true:$router.push('/goldLog')">
          <img src="@/assets/images/index/goldLog.png" style="width: 50px;" />
          <div>{{$t('index.i_coinsLog')}}</div>
        </div>
      </div>

      <!-- 底部菜单项 -->
      <div class="bottom-menu-bar" style="margin-top: 16%;">
        <!-- 兑换 -->
        <el-button @click="data.userInfo.failMessage!=null?checkDialog=true:$router.push('/convert')" style="position: relative;padding: 0;border-radius: 14px;">
          <img src="@/assets/images/index/convertCard.png" style="width: 110px;" />
          <span style="position: absolute;box-sizing: border-box;left: 12px;top: 20px;text-align: left;color: white;">
            <span style="font-size: 14px;font-weight: bold;">{{$t('index.i_convert')}}</span>
            <br />
            <span style="line-height: 24px;font-size: 12px;font-weight: 100;">{{$t('index.i_convertMsg')}}</span>
          </span>
        </el-button>

        <!-- 邀请好友 -->
        <el-button @click="data.userInfo.failMessage!=null?checkDialog=true:$router.push('/inviteFriend')" style="position: relative;padding: 0;border-radius: 14px;">
          <img src="@/assets/images/index/inviteFriendsCard.png" style="width: 110px;" />
          <span style="position: absolute;box-sizing: border-box;left: 12px;top: 20px;text-align: left;color: white;">
            <span style="font-size: 14px;font-weight: bold;">{{$t('index.i_inviteFriends')}}</span>
            <br />
            <span style="line-height: 24px;font-size: 12px;font-weight: 100;">{{$t('index.i_inviteFriendsMsg')}}</span>
          </span>
        </el-button>

        <!-- 任务中心 -->
        <el-button @click="data.userInfo.failMessage!=null?checkDialog=true:$router.push('/task')" style="position: relative;padding: 0;border-radius: 14px;">
          <img src="@/assets/images/index/taskCenterCard.png" style="width: 110px;" />
          <span style="position: absolute;box-sizing: border-box;left: 12px;top: 20px;text-align: left;color: white;">
            <span style="font-size: 14px;font-weight: bold;">{{$t('task.i_task')}}</span>
            <br />
            <span style="line-height: 24px;font-size: 12px;font-weight: 100;">{{$t('task.i_taskMsg')}}</span>
          </span>
        </el-button>
      </div>

      <!-- 账户中心 -->
      <el-dialog :title="$t('index.i_accountC')" :visible.sync="userInfoDialog" width="80%" center>
        <div style="width: 100%;text-align: center;background-color: rgba(0, 111, 255, 1);padding-top: 9%;padding-bottom: 8%;border-radius: 20px;">
          <img src="@/assets/images/index/user.png" style="margin-bottom: 5%;width: 30%;" />
          <table style="margin: auto;font-size: 18px;">
            <tr style="height: 35px;">
              <td style="text-align: right;width: 76px;color: rgba(142, 231, 255, 1);">UID：</td>
              <td style="text-align: left;color: white;">{{data.userInfo.uid}}</td>
            </tr>
            <tr v-if="data.userInfo.inviteCode != null" style="height: 35px;">
              <td style="text-align: right;width: 76px;color: rgba(142, 231, 255, 1);">{{$t('index.i_inviteCode')}}：</td>
              <td style="text-align: left;color: white;">
                <span @click="copyVal($event.target.innerText)">{{data.userInfo.inviteCode}}</span>
                <span style="margin-left: 4px;" @click="copyVal($event.currentTarget.previousElementSibling.innerText)">
                  <img src="@/assets/common/copyIconW.png" style="margin-bottom: 10px;width: 10px;" />
                </span>
              </td>
            </tr>
          </table>
        </div>
      </el-dialog>

      <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnPressEscape="false">
        <div style="text-align: center;margin-bottom: 10%;">
          <div style="font-size: 16px;color: #000;">{{ data.userInfo.failMessage }}</div>
        </div>
        <div style="text-align: center;">
          <el-button round type="primary" @click="go()" style="width: 60%;">
            {{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </el-dialog>

      <el-drawer size="45%" direction="btt" :with-header="false" :show-close="false" :visible.sync="contactDrawer">
        <div>
          <div style="overflow: hidden;margin-top: 5%;margin-left: 5%;margin-right: 5%;">
            <span style="float: left;font-size: 20px;color: black;">{{$t('index.i_contact')}}</span>
          </div>
          <div style="background-color: rgba(223, 223, 227, 1);margin: 5%;padding-top: 3%;padding-bottom: 3%;border-radius: 6px;">
            <span style="">
              <a :href="contact" style="word-wrap: break-word;">{{this.contact}}</a>
            </span>
          </div>
          <div>
            <img src="@/assets/images/index/goBrowser.png" style="width: 100px;" />
            <div style="margin-top: 2%;margin-bottom: 5%;color: rgba(127, 127, 149, 1);">{{ $t('index.i_browserOpen') }}</div>
          </div>
          <div>
            <el-button round type="primary" @click="copyVal($event.currentTarget.parentElement.previousElementSibling.previousElementSibling.firstElementChild.innerText)" style="width: 38%;background-color: rgba(0, 111, 255, 1);">
              <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
              &nbsp;
              <span style="font-size: 16px;">Copy</span>
            </el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // 加载屏蔽层状态
        isLoading: false,
        checkDialog: false,
        userInfoDialog: false,
        data: {
          appName: "",
          userInfo: {
            uid: 0,
            goldNum: 0,
            inviteCode: "",
            appScheme: "",
            check: "",
            failMessage: ""
          }
        },
        contactDrawer: false,
        contact: "https://chat.whatsapp.com/JznJyQPwuQu8Pjp0nMuqao"
      };
    },
    methods: {
      copyVal(val) {
        // 创建一个 Input标签
        let oInput = document.createElement("input");
        oInput.value = val;
        document.body.appendChild(oInput);
        // 选择对象
        oInput.select();
        // 执行浏览器复制命令
        // 复制命令会将当前选中的内容复制到剪切板中
        // 如这里构建的 Input标签
        document.execCommand("Copy");
        this.$message({
          message: this.$t("common.i_copy"),
          type: "success",
          showClose: true,
          center: true
        });
        // 复制成功后再将构造的标签 移除
        oInput.remove();
      },
      getIndex() {
        this.isLoading = true;
        this.$axios({
          url: "/webGift/index",
          method: "get"
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            this.data = result.data.data;
            this.data.userInfo.check = result.data.data.check;
            this.data.userInfo.failMessage = result.data.data.failMessage;
            this.$store.state.goldNum = result.data.data.userInfo.goldNum;
            localStorage.setItem("appScheme", this.data.userInfo.appScheme);
          } else if (result.data.code == 0) {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });
      },
      goApp() {
        window.location.href = this.data.userInfo.appScheme;
      },
      go() {
        if (this.data.userInfo.check == "login") {
          window.location.href = this.data.userInfo.appScheme + "login";
        } else {
          window.location.href = this.data.userInfo.appScheme;
        }
      }
    },
    created() {
      if (this.$route.query.token != null) {
        localStorage.setItem("token", this.$route.query.token);
      }
      this.getIndex();
    }
  };
</script>

<style>
  #index-layout {
    width: 100%;
    height: 100%;
  }
  #index-layout .i-bg-img {
    background-image: url("@/assets/images/index/indexHeaderBg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #59b468;
  }
  #index-layout .i-page-header {
    overflow: hidden;
    padding: 4% 4% 0 4%;
    color: white !important;
  }
  #index-layout .el-page-header__left::after {
    display: none;
  }
  #index-layout .bottom-menu-bar .el-button {
    border: none;
    background: none;
  }
  #index-layout .el-drawer {
    border-radius: 20px 20px 0 0 !important;
  }
</style>
